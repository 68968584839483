import * as React from "react"

import Home from "../components/home/home"
import Layout from "../components/layout"
import Seo from "../components/seo"

const logo = require("../images/whitelogo.svg").default

const IndexPage = () => (
  <Layout
    backgroundColor="black"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="white"
    footerColor="black"
  >
    <Seo title="Rajyashree Ramesh" />
    <Home />
  </Layout>
)

export default IndexPage
