import React, { Fragment } from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  CardTitle,
  ImageCardContainer,
  ImageContainer,
  ImageFadeIn,
  ImageScaleTransition,
  ImgDesc,
  ImgTitle,
} from "../commons/HighlightsCards.style"
import { Title, Wrapper } from "../performer/performerStyles"
import {
  LandingContent,
  DanceText,
  DanceTextMobile,
  HomeImageContainer,
} from "./homestyles"
import { Link } from "gatsby"

const Home = () => (
  <Fragment>
    <ImageContainer>
      <ImageFadeIn>
        <StaticImage
          src="../../images/Home/hero-video.jpg"
          alt="Image"
          style={{ width: "100%" }}
          placeholder="dominantColor"
        />
      </ImageFadeIn>
      <CardTitle>
        <LandingContent>
          When movement becomes the driving force, every rhythm, emotion,
          narrative, idea, concept transmutes, becoming meaningful in varied
          ways as it emerges forth as a tangible embodied entity. So dance!
        </LandingContent>
      </CardTitle>
    </ImageContainer>

    <Wrapper>
      <Title>
        A dance journey spanning six decades of collective experience. <br />
        Practitioner to Researcher
      </Title>

      <ImageCardContainer gap="16px">
        <Link to="/practitioner">
          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Home/practice.svg"
                alt="practitioner"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitle>practice</ImgTitle>
                <ImgDesc>performance</ImgDesc>
                <ImgDesc>choreography</ImgDesc>
                <ImgDesc>dance education</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>
        </Link>

        <Link to="/researcher">
          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Home/research.svg"
                alt="researcher"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitle>research</ImgTitle>
                <ImgDesc>ancient dance</ImgDesc>
                <ImgDesc>modern science</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>
        </Link>

        <Link to="/movement-studies">
          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Home/studies.svg"
                alt="studies"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitle>movement</ImgTitle>
                <ImgTitle>studies</ImgTitle>
                <ImgDesc>movement analytical-</ImgDesc>
                <ImgDesc>fascial</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>
        </Link>
      </ImageCardContainer>
    </Wrapper>

    <HomeImageContainer>
      <StaticImage
        src="../../images/Home/footer.svg"
        placeholder="dominantColor"
        alt="home page"
      />
      <CardTitle>
        <DanceText>
          Dance plunges one into that vast ocean, exploring the depth of which
          becoming a lifelong journey of quest, insightful experiences and
          chance encounters.
        </DanceText>
      </CardTitle>
    </HomeImageContainer>
    <DanceTextMobile style={{ textTransform: 'none' }}>
  Dance plunges one into that vast ocean, exploring the depth of which
  becoming a lifelong journey of quest, insightful experiences and chance
  encounters.
</DanceTextMobile>
  </Fragment>
)

export default Home
